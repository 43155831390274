<template>
  <div>

    <!-- Overview Block-->
    <v-sheet class="wsRoundedHalf pa-6 mt-6">
      <v-simple-table >
        <tbody>
        <tr>
          <td>
            <h5 class="font-weight-light">{{ $t('PaymentPlan') }}</h5>
          </td>
          <td>
            <v-chip :color="entity.business_plan_color" outlined >
              <h4 class="text-no-wrap font-weight-medium ">{{ entity.business_plan_name}} </h4>
            </v-chip>

          </td>
        </tr>

        <tr>
          <td>
            <h5 :style="`color : ${ entity.days_left > 0 ? '' : 'red'  }`" class="font-weight-light">{{ $t('ActiveTill') }} </h5>
          </td>
          <td>
            <h5 class="text-no-wrap font-weight-medium mr-3" :style="`color : ${ entity.days_left > 0 ? '' : 'red'  }`">
              <ws-date-picker
                  v-if="entity.active_date"
                  v-model="entity.active_date"
                  @input="changeActiveDate($event)"
                  custom-activator
              >
                <v-icon :color="entity.days_left > 0 ? wsACCENT : 'red'">mdi-calendar</v-icon>
                {{ entity.active_date}}
                <v-icon>mdi-menu-down</v-icon>
              </ws-date-picker>

            </h5>
          </td>
          <td>
            <div v-if="entity.days_left < 0">
              <h5 class="red--text font-weight-medium text-center" style="line-height: 1.2">
                Прострочено на {{ Math.abs(entity.days_left) }} днів
              </h5>
            </div>
          </td>
        </tr>


        <tr>
          <td>
            <h5 class="font-weight-light"
                :style="`color : ${ entity.student_profiles_total <= entity.student_profiles_quota ? '' : 'red'  }`"
            >{{ $t('StudentProfiles') }} </h5>
          </td>
          <td>
            <h5 class="text-no-wrap font-weight-medium mr-3"
                :style="`color : ${ entity.student_profiles_total <= entity.student_profiles_quota ? '' : 'red'  }`"
            >
              <v-icon :color="entity.student_profiles_total <= entity.student_profiles_quota ? wsACCENT : 'red'">mdi-account-school</v-icon>
              {{ entity.student_profiles_total }} / {{ entity.student_profiles_quota }}
            </h5>
          </td>
          <td>
            <div v-if="entity.student_profiles_total > entity.student_profiles_quota">
              <h5 class="red--text font-weight-medium text-center" style="line-height: 1.2">
                Перебільшено на {{ entity.student_profiles_total - entity.student_profiles_quota }}
              </h5>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <h5 class="font-weight-light"
                :style="`color : ${ entity.managers_total <= entity.managers_quota ? '' : 'red'  }`"
            >{{ $t('Managers') }} </h5>
          </td>
          <td>
            <h5 class="text-no-wrap font-weight-medium mr-3"
                :style="`color : ${ entity.managers_total <= entity.managers_quota ? '' : 'red'  }`"
            >
              <v-icon :color="entity.managers_total <= entity.managers_quota ? wsACCENT : 'red'">mdi-account-tie</v-icon>
              {{ entity.managers_total }} / {{ entity.managers_quota }}
            </h5>
          </td>
          <td>
            <div v-if="entity.managers_total > entity.managers_quota">
              <h5 class="red--text font-weight-medium text-center" style="line-height: 1.2">
                Перебільшено на {{ entity.managers_total - entity.managers_quota }}
              </h5>
            </div>
          </td>
        </tr>

        </tbody>
      </v-simple-table>
    </v-sheet>
    <!-- Installed Modules Block-->
    <v-sheet class="wsRoundedHalf pa-6 mt-6">

      <h3 class="font-weight-medium"> {{ $t('Modules') }}</h3>

      <v-simple-table class="mt-6" dense>
        <tbody>
        <tr v-for="(module,j) in entity.modules" :key="'modules' + j">
          <td>
            <h5 :class="[{'font-weight-light' : !module.value}]">{{ module.name }}</h5>
          </td>
          <td width="10px" align="right" class="text-no-wrap">
            <ft-select
                @input="changeModule(module)" v-model="module.value" :items="CHOICE_ACTIVE">
              <h5 :style="`color : ${module.value ? wsSUCCESS : ''}`" :class="[{'font-weight-light' : !module.value}]">
                {{ module.value ? $t('Active') : $t('NotActive') }}
                <v-icon :color="module.value ? wsSUCCESS : ''">mdi-chevron-down</v-icon>
              </h5>
            </ft-select>
          </td>
        </tr>
        </tbody>
      </v-simple-table>

    </v-sheet>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "businessCardPaymentPlan",
  props : {
    uuid : {
      type : String,
    }
  },
  data() {
    return {
      modules : [],
      plan : {},
      entity : {},
      entityData : {}
    }
  },
  methods : {
    ...mapActions('adminCrmSystem', [
      'GET_BUSINESS_PLAN_QUOTAS',
      'EDIT_BUSINESS_MODULE',
      'EDIT_BUSINESS_DATE'
    ]),

    changeActiveDate(date) {
      let data = {
        uuid : this.uuid,
        date : date
      }
      let result = this.EDIT_BUSINESS_DATE(data)
      if ( !result ) {
        return
      }
      this.notify(this.$t('ChangesSaved'))
      this.initPage()
    },

    changeModule(module) {
      let data = JSON.parse(JSON.stringify(module))
      data.uuid = this.uuid
      let result = this.EDIT_BUSINESS_MODULE(data)
      if ( !result ) {
        return
      }
      this.notify(this.$t('ChangesSaved'))
    },

    async initPage() {
      let result = await this.GET_BUSINESS_PLAN_QUOTAS(this.uuid)
      if ( !result ) {
        return
      }
      this.entity = result
    }

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>