<template>
  <div>
    <h3>{{ $t('Timeline') }}</h3>
    <v-timeline class="mt-6"  dense clipped >

      <!-- NEW EVENT -->
      <v-timeline-item  :color="wsACCENT">

        <template #icon><v-icon dark>mdi-plus</v-icon></template>

        <v-slide-x-transition mode="out-in">

          <v-sheet v-if="!addFile" class="wsRoundedHalf pa-3" :color="wsLIGHTCARD"  min-height="200">



            <v-text-field v-model="entityData.name" class="font-weight-bold" style="font-size: 0.83em" background-color="transparent" flat solo :placeholder="$t('EnterEventName')" dense  hide-details>
              <template #append>

                <div class="d-flex  align-center ">

                  <ft-select v-model="entityData.is_completed" :items="CHOICE_COMPLETED">
                    <wsChip :color="entityData.is_completed ? wsACCENT : 'grey'" centered class="mr-3"
                            :icon="entityData.is_completed ? 'mdi-check' : 'mdi-cog'"
                            :text="entityData.is_completed  ? $t('Completed')  : $t('InProcess')"  >
                      <v-icon small>mdi-chevron-down</v-icon>
                    </wsChip>
                  </ft-select>

                  <ft-select v-model="entityData.type" :items="eventTypes" :color="wsACCENT">
                    <template v-slot:default="{text}">
                      <v-chip :color="wsACCENT" outlined  class="px-4" >
                        <v-icon small class="mr-1">{{ getEventIcon(entityData.type) }}</v-icon>
                        <h5 class="font-weight-medium">{{ text }}</h5>
                      </v-chip>
                    </template>
                  </ft-select>

                </div>

              </template>
            </v-text-field>

            <v-sheet color="transparent" >
              <v-textarea v-model="entityData.text"
                          style="font-size: 0.83em"
                          row-height="1" single-line
                          background-color="transparent"  auto-grow flat solo :placeholder="$t('EnterEventText')" dense  hide-details ></v-textarea>
            </v-sheet>

            <div class="d-flex px-3 mt-3 mb-6">
              <h5 class="mr-2">{{ $t('Manager') }}: </h5>
              <wsCrmAdminManagersSelect class="ml-3 "  v-model="entityData.manager_id" />
            </div>
            <div class="d-flex px-3 mt-3 mb-6">
              <h5 class="mr-2">{{ !entityData.is_completed ? $t('DateScheduled') : $t('DateCompleted') }}: </h5>
              <ws-date-picker v-model="entityData.date_scheduled"
                              custom-activator>
                  <wsChip :text="!entityData.date_scheduled ? $t('Today') : entityData.date_scheduled"
                          :color="wsAccent"
                          icon="mdi-calendar" >
                  </wsChip>
              </ws-date-picker>
            </div>


            <div class="d-flex pl-n3 pr-6 ">
              <v-btn block large elevation="0" rounded height="32" @click="addEvent"  rouned outlined :color="wsACCENT" class="noCaps ml-3 align-center">
                {{ $t('AddEvent') }}
              </v-btn>
            </div>



          </v-sheet>
          <wsVideoUpload v-if="addFile" superadmin no-id all-types @success="fileUploaded"   />
        </v-slide-x-transition>

        <v-simple-table class="mt-3" dense >
          <tbody>
          <tr v-for="(item,j) in entityData.files" :key="'files_' + j">
            <td style="border-bottom: none" width="10px"><v-icon :color="wsACCENT" small>mdi-file</v-icon></td>
            <td style="border-bottom: none" >{{ item.name }}</td>
          </tr>
          </tbody>
        </v-simple-table>

        <v-btn :color="wsACCENT" @click="addFile = !addFile" block text small class="noCaps mt-3"> {{ !addFile ? $t('AddFile') : $t('Back') }} </v-btn>




      </v-timeline-item>
      <!-- Events-->
      <v-timeline-item v-for="(item,i) in items"
                       :key="i"
                       class="my-3"
                       :color="getEventColor(item.type)">
        <template #icon><v-icon dark small>{{ getEventIcon(item.type) }}</v-icon></template>
        <v-sheet>
          <div class="d-flex justify-space-between align-center">
            <h5>{{ item.name }}</h5>
            <wsChip icon="mdi-calendar" :text="item.date_created" />
          </div>

          <h5 v-html="item.text" class="font-weight-medium mt-3 mb-6" />

          <v-simple-table dense>
            <tbody>
            <tr class="pointer" @click="openFile(item)" v-for="(item,j) in item.files" :key="'files_' + j">
              <td style="border-bottom: none" width="10px"><v-icon :color="wsACCENT" small>mdi-file</v-icon></td>
              <td style="border-bottom: none">{{ item.name }}</td>
            </tr>
            </tbody>
          </v-simple-table>

          <div class="d-flex justify-space-between">

          </div>

          <wsChip class="mt-6" icon="mdi-account-tie" :text="item.owner_name" />


        </v-sheet>
      </v-timeline-item>

    </v-timeline>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import wsVideoUpload from "@/components/UI/videoPlayer/wsVideoUpload";
import wsCrmAdminManagersSelect from "@/components/pages/adminDashboard/UI/wsCrmAdminManagersSelect";

export default {
  name: "businessCardTimeline",
  components : {
    wsVideoUpload,
    wsCrmAdminManagersSelect
  },
  props : ['uuid'],
  data() {
    return {
      items : [],
      addFile : false,
      entityData : {
        type : 'note',
        files : [],
        is_completed : true
      },
      defaultEntityData : {
        type : 'note',
        files : [],
        is_completed : true
      },
      selectedFile : {},
    }
  },
  computed: {
    eventTypes() {
      return [
        { text : this.$t('Note')      , value : 'note'       },
        { text : this.$t('Call')      , value : 'call'       },
        { text : this.$t('VideoCall') , value : 'video_call' },
        { text : this.$t('Task')      , value : 'task'       },
        { text : this.$t('File')      , value : 'file'       },
        { text : this.$t('Bug')       , value : 'bug'       }
      ]
    }
  },
  methods : {
    ...mapActions('adminCrmSystem', [
        'ADMIN_CRM_GET_BUSINESS_EVENTS',
        'ADMIN_CRM_ADD_BUSINESS_EVENT',
        'GET_SUPERADMIN_FILE'
    ] ),

    async openFile(file) {
      let data =  {
        file : file.uuid,
        course : this.uuid
      }
      let result = await this.GET_SUPERADMIN_FILE(data)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      if (result.mime.includes('image')) {
        a.target = "_blank";
      }

      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },

    fileUploaded($event) {
      this.entityData.files.push($event)
      this.addFile = false
      this.notify(this.$t('FileUploaded'))
    },

    async addEvent() {
      this.entityData.business_id = this.uuid
      this.entityData.text = this.entityData.text.replace(/\n/gi, '<br>');
      let result = await this.ADMIN_CRM_ADD_BUSINESS_EVENT(this.entityData)
      if (!result) {
        return this.notify(this.$t('Error'))
      }
      this.items.unshift(result)
      this.entityData = JSON.parse(JSON.stringify(this.defaultEntityData))
      this.notify(this.$t('ChangesSaved'))
    },

    // technical

    getEventIcon(type) {
      switch(type) {
        case 'note' : return 'mdi-notebook';
        case 'call' : return 'mdi-phone';
        case 'video_call' : return 'mdi-video';
        case 'task' : return 'mdi-checkbox-marked-outline';
        case 'file' : return 'mdi-file';
        case 'registration' : return 'mdi-briefcase-outline'
        case 'status_change' : return 'mdi-cog';
        case 'bug' : return 'mdi-bug';
        default : return 'mdi-cog';
      }
    },
    getEventColor(type) {
      switch(type) {
        case 'note' : return 'orange';
        case 'call' : return 'green lighten-1';
        case 'video_call' : return 'blue lighten-1';
        case 'task' : return 'teal lighten-1';
        case 'file' : return 'primary lighten-1';
        case 'registration'  : return 'green lighten-2';
        case 'status_change' : return 'purple lighten-1';
        case 'bug' : return 'red lighten-2';
        default : return 'mdi-cog';
      }
    },

    async initPage() {
      if ( this.uuid ) {
        let result = await this.ADMIN_CRM_GET_BUSINESS_EVENTS(this.uuid);
        if ( result )
          this.items = result !== true ? result : []
      }
    }

  },
  mounted(){
    this.entityData.manager_id = this.$store.state.auth.userID
    this.initPage()

  }
}
</script>

<style scoped>

</style>