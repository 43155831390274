<template>
<div>
  <ws-button
      label="Створити платіж"
      @click="openAddPayment"
      block
      outlined
  />

  <ws-data-table
      :headers="headers"
      :items="items"
      class="mt-5"
  >

    <template #item.date_created="{item}">
      <h5 class="text-no-wrap">{{ PARSE_DATE(item.date_created , false , true) }}</h5>
    </template>

    <template #item.business_name="{item}">
      <h5 class="wsACCENT">{{ item.business_name }}</h5>
    </template>

    <template #item.service="{item}">
      <h5 >{{ getServiceName(item.service) }}</h5>
    </template>

    <template #item.is_payed="{item}">
      <v-icon :color="item.is_payed ? wsSUCCESS : wsBACKGROUND">
        mdi-check-circle
      </v-icon>
    </template>

  </ws-data-table>


  <ws-dialog
      v-model="displayDialog"
      title="Cтворити платіж"
      @save="addPayment"
  >

    <v-row no-gutters>
      <v-col cols="6" class="pr-1">
        <ws-select
            label="Послуга"
            class="mt-5"
            placeholder="Оберіть послугу"
            v-model="entityData.service"
            :items="servicesSelect"
            :error="serviceError"
            @input="serviceError = false"
            autocomplete
        />
      </v-col>

      <v-col cols="6" class="pl-1">
        <ws-text-field
            v-model="entityData.price"
            :error="priceError"
            @input="priceError = false"
            class="mt-5"
            label="Сума"
            placeholder="Вкажіть суму"
            append-icon="mdi-currency-usd"
            number
        />
      </v-col>
    </v-row>

    <v-expand-transition>
      <ws-text-field
          v-if="entityData.service === 'payment_plan'"
          v-model="entityData.duration"
          class="mt-5"
          label="Термін підписки"
          placeholder="Вкажіть термін в місяцях"
          append-icon="mdi-calendar"
          number
      />
    </v-expand-transition>

    <ws-date-picker
        class="mt-5"
        label="Дата"
        placeholder="Сьогоднішня дата"
        v-model="entityData.date"
    />

    <ws-switch
        class="pt-5"
        v-model="entityData.is_payed"
        placeholder="Оплачено"
    />

  </ws-dialog>

</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "businessCardPayments",
  props : {
    uuid : {
      type : String
    }
  },
  data() {
    return {
      displayDialog : false,
      entityData : {},
      items : [],
      servicesSelect : [
        { text : "Підписка" , value : 'payment_plan' },
        { text : "Виділений сервер" , value : 'dedicated_server' },
        { text : "Налаштування сайту" , value : 'web_site' },
        { text : "Налаштування контету" , value : 'content' },
        { text : "Інше" , value : 'other' },
      ],
      businessError : false,
      priceError : false,
      serviceError : false,
    }
  },
  computed : {
    headers() {
      return [
        { text : 'Дата'    , value : 'date_created' , width : 10 },
        { text : 'Послуга' , value : 'service'  },
        { text : 'Номер'    , value : 'order_id' , width : 10 },
        { text : 'Cума'    , value : 'price' , width : 10 },
        { text : 'Статус'  , value : 'is_payed' ,width : 10  },
      ]
    }
  },
  methods : {
    ...mapActions('adminPayments', [
      'GET_BUSINESS_PAYMENTS',
      'ADD_PAYMENT',
    ]),

    async addPayment() {
      if ( !this.entityData.business ) {
        this.businessError = true
        return this.notify('Оберіть бізнес для створення рахунку')
      }
      if ( !this.entityData.price ) {
        this.priceError = true
        return this.notify('Вкажіть суму рахунку')
      }
      if ( !this.entityData.service ) {
        this.serviceError = true
        return this.notify('Оберіть тип послуги')
      }
      let result = await this.ADD_PAYMENT(this.entityData)
      if ( !result ) {
        return this.ERROR()
      }
      this.items.unshift(result)
      this.displayDialog = false
    },
    openAddPayment() {
      this.entityData = {
        business : this.uuid
      }
      this.displayDialog = true
    },
    getServiceName(service) {
      switch(service) {
        case 'payment_plan' : return 'Підписка'
        case 'payment_plan_change' : return 'Зміна тарифу'
        case 'dedicated_server' : return 'Виділений сервер'
        case 'web_site' : return 'Налаштування сайту'
        case 'content' : return 'Налаштування контету'
        case 'other' : return 'Інше'
        default : return service
      }
    },


    async initPage() {
      let result = await this.GET_BUSINESS_PAYMENTS(this.uuid)
      if ( !result ) {
        return this.ERROR()
      }
      result.items.reverse()
      this.items = result.items
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>