<template>
  <div>
    <h1>dashboard</h1>
  </div>
</template>

<script>
export default {
  name: "businessCardDashboard"
}
</script>

<style scoped>

</style>