<template>
  <div>
    <v-simple-table  >
      <tbody>

      <tr>
        <td width="100px"><h5 class="font-weight-light">Назва</h5></td>
        <td align="left"><h5 class="font-weight-medium">{{ entity.name }}</h5></td>
      </tr>
      <tr>
        <td width="10px"><h5 class="font-weight-light">Статус</h5></td>
        <td align="left">
          <ft-select v-model="entity.crm_status" @input="changeStatus($event, entity )" :items="statusTypes" >
            <template v-slot:default="{text}">
               <h5 class="d-flex align-center">{{ text}} <v-icon small>mdi-chevron-down</v-icon> </h5>
            </template>
          </ft-select>
        </td>
      </tr>
      <tr>
        <td width="10px"><h5 class="font-weight-light">Аліас</h5></td>
        <td align="left"><h5 class="font-weight-medium">{{ entity.alias }}</h5></td>
      </tr>
      <tr>
        <td width="10px"><h5 class="font-weight-light">Домен</h5></td>
        <td align="left"><h5 class="font-weight-medium">{{ entity.domain }}</h5></td>
      </tr>
      <tr>
        <td width="10px"><h5 class="font-weight-light">Клієнт</h5></td>
        <td align="left"><h5 class="font-weight-medium">{{ entity.user_name }}</h5></td>
      </tr>
      <tr>
        <td width="10px"><h5 class="font-weight-light">Телефон</h5></td>
        <td align="left"><h5 class="font-weight-medium">{{ entity.user_phone }}</h5></td>
      </tr>
      <tr>
        <td width="10px"><h5 class="font-weight-light">Пошта</h5></td>
        <td align="left"><h5 class="font-weight-medium">{{ entity.user_email }}</h5></td>
      </tr>
      <tr>
        <td width="10px"><h5 class="font-weight-light">Телеграм</h5></td>
        <td align="left">
          <v-text-field @change="changeBusinessProperty($event,'telegram_link')" class="font-weight-medium mt-n1 ml-n3" style="font-size: 0.83em" background-color="transparent" v-model="entity.telegram_link" solo flat dense hide-details placeholder="Введіть телеграм"></v-text-field>
        </td>
      </tr>
      <tr>
        <td width="10px"><h5 class="font-weight-light">Вайбер</h5></td>
        <td align="left">
          <v-text-field @change="changeBusinessProperty($event,'viber_link')" class="font-weight-medium mt-n1 ml-n3" style="font-size: 0.83em" background-color="transparent" v-model="entity.viber_link" solo flat dense hide-details placeholder="Введіть вайбер"></v-text-field>
        </td>
      </tr>
      <tr>
        <td width="10px"><h5 class="font-weight-light">Веб сайт</h5></td>
        <td align="left">
          <v-text-field @change="changeBusinessProperty($event,'old_website')" class="font-weight-medium mt-n1 ml-n3" style="font-size: 0.83em" background-color="transparent" v-model="entity.old_website" solo flat dense hide-details placeholder="Введіть веб сайт"></v-text-field>
        </td>
      </tr>

      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "businessCardOverview",
  props : ['entity'],
  computed : {
    statusTypes() {
      return [
        {  text: this.$t('Новий')         ,  value: 'new'          },
        {  text: this.$t('Контактували')  ,  value: 'contacted'    },
        {  text: this.$t('Не відповів')   ,  value: 'no_contact'   },
        {  text: this.$t('В процесі')     ,  value: 'in_process'   },
        {  text: this.$t('Чекають')       ,  value: 'waiting'   },
        {  text: this.$t('Перспективний') ,  value: 'perspective'   },
        {  text: this.$t('Cоціальний')    ,  value: 'social'   },
        {  text: this.$t('Успіх')         ,  value: 'success'      },
        {  text: this.$t('Відмова')       ,  value: 'refuse'       },
      ]

    },
  },
  methods : {
    ...mapActions('adminCrmSystem', [
      'ADMIN_CRM_EDIT_BUSINESS_PROPERTY',
      'ADMIN_CRM_EDIT_BUSINESS_STATUS',
    ] ),


    async changeBusinessProperty(value,property) {
      let data = {
        business_id : this.entity.uuid,
        property : property,
        value : value
      }

      if (!await this.ADMIN_CRM_EDIT_BUSINESS_PROPERTY(data)) {
        return this.notify(this.$t('Error'))
      }

      this.notify(this.$t('ChangesSaved'))

    },
    async changeStatus(status,item) {
  let data = {
    uuid : item.uuid,
    status : status
  }
  let result = await this.ADMIN_CRM_EDIT_BUSINESS_STATUS(data)
  if ( !result ) { return this.notify(this.$t('Error')) }
  item.crm_status = status
  this.notify(this.$t('ChangesSaved'))
},
  },
  mounted(){
  }
}
</script>

<style scoped>

</style>