<template>
  <dash-page :title="entity.name"
             subtitle="Картка клієнта"
             :headerRoute="localeLink('dashboard/businesses')">


    <template #default>


      <v-sheet class="wsRoundedHalf  mt-6 pa-6">

        <div>
          <v-btn-toggle borderless mandatory v-model="$store.state.adminCrmSystem.businessCardview" background-color="transparent" :color="wsACCENT"   >
            <!--          <v-btn height="30" small text  class="noCaps font-weight-light" value="dashboard">-->
            <!--            <v-icon small class="mr-1">mdi-table</v-icon>-->
            <!--            {{ $t('DashboardCrm') }}-->
            <!--          </v-btn>-->
            <v-btn  text  class="noCaps font-weight-light" value="timeline">
              <v-icon :color="wsACCENT"  class="mr-1">mdi-calendar</v-icon>
              <h4 class="font-weight-medium">{{ $t('Timeline') }}</h4>
            </v-btn>
            <!--          <v-btn height="30" small text  class="noCaps font-weight-light" value="Files">-->
            <!--            <v-icon small class="mr-1">mdi-view-dashboard-variant-outline</v-icon>-->
            <!--            {{ $t('Files') }}-->
            <!--          </v-btn>-->
            <v-btn   text  class="noCaps font-weight-light" value="overview">
              <v-icon :color="wsACCENT"  class="mr-1">mdi-table</v-icon>
              <h4 class="font-weight-medium">{{ $t('Overview') }}</h4>
            </v-btn>
            <v-btn   text  class="noCaps font-weight-light" value="quotas">
              <v-icon :color="wsACCENT"  class="mr-1">mdi-cash-multiple</v-icon>
              <h4 class="font-weight-medium">{{ $t('PaymentPlan') }}</h4>
            </v-btn>

            <v-btn
                v-if="HAS_ROLES('ROLE_ULTRAADMIN') || (!HAS_ROLES('ROLE_ULTRAADMIN') && HAS_ROLES('WS_BUSINESS_PAYMENTS') )"
                text  class="noCaps font-weight-light" value="payments">
              <v-icon :color="wsACCENT"  class="mr-1">mdi-cash</v-icon>
              <h4 class="font-weight-medium">Оплати</h4>
            </v-btn>

          </v-btn-toggle>
        </div>

        <div class="mt-6">


          <businessCardTimeline  key="time" v-if="$store.state.adminCrmSystem.businessCardview === 'timeline'  " :uuid="uuid" />
          <businessCardDashboard key="dash" v-if="$store.state.adminCrmSystem.businessCardview === 'dashboard' " :uuid="uuid" />
          <businessCardOverview  key="view" v-if="$store.state.adminCrmSystem.businessCardview === 'overview'  " :uuid="uuid" :entity="entity" />
          <business-card-payment-plan  key="quotas" v-if="$store.state.adminCrmSystem.businessCardview === 'quotas'  " :uuid="uuid" />
          <business-card-payments
              key="payments"

              v-if="$store.state.adminCrmSystem.businessCardview === 'payments' && (HAS_ROLES('ROLE_ULTRAADMIN') || (!HAS_ROLES('ROLE_ULTRAADMIN') && HAS_ROLES('WS_BUSINESS_PAYMENTS') ))" :uuid="uuid" />
        </div>

      </v-sheet>

    </template>

    <template #side>
      <div class="d-flex justify-center">
        <img width="100%" class="mx-auto"   :src="entity.logo" />
      </div>
      <v-simple-table class="mt-6" style="background-color: transparent" dense >
        <tbody>

        <tr>
          <td width="10px"><h5 class="font-weight-light">Назва</h5></td>
          <td align="left"><h5 class="font-weight-medium">{{ entity.name }}</h5></td>
        </tr>
        <tr>
          <td width="10px"><h5 class="font-weight-light">Статус</h5></td>
          <td align="left"><h5 class="font-weight-medium">{{ entity.crm_status }}</h5></td>
        </tr>
        <tr>
          <td width="10px"><h5 class="font-weight-light">Аліас</h5></td>
          <td align="left"><h5 class="font-weight-medium">{{ entity.alias }}</h5></td>
        </tr>
        <tr>
          <td width="10px"><h5 class="font-weight-light">Домен</h5></td>
          <td align="left"><h5 class="font-weight-medium">{{ entity.domain ? entity.domain : $t('None') }}</h5></td>
        </tr>
        <tr>
          <td width="10px"><h5 class="font-weight-light">Клієнт</h5></td>
          <td align="left"><h5 class="font-weight-medium">{{ entity.user_name }}</h5></td>
        </tr>
        <tr>
          <td width="10px"><h5 class="font-weight-light">Телефон</h5></td>
          <td align="left"><h5 class="font-weight-medium">{{ entity.user_phone }}</h5></td>
        </tr>
        <tr>
          <td width="10px"><h5 class="font-weight-light">Пошта</h5></td>
          <td align="left"><h5 class="font-weight-medium">{{ entity.user_email }}</h5></td>
        </tr>

        </tbody>
      </v-simple-table>
    </template>

  </dash-page>
</template>

<script>
import {mapActions} from "vuex";
import businessCardTimeline from "@/components/pages/adminDashboard/businesses/businessCardTimeline";
import businessCardDashboard from "@/components/pages/adminDashboard/businesses/businessCardDashboard";
import businessCardOverview from "@/components/pages/adminDashboard/businesses/businessCardOverview";
import businessCardPaymentPlan from "@/components/pages/adminDashboard/businesses/businessCardPaymentPlan";
import businessCardPayments from "@/components/pages/adminDashboard/businesses/businessCardPayments";
export default {
  name: "crmAdminBusiness",
  props : ['uuid'],
  components : {
    businessCardTimeline,
    businessCardDashboard,
    businessCardOverview,
    businessCardPaymentPlan,
    businessCardPayments
  },
  data() {
    return {
      entity : {
        name : ''
      },
    }
  },
  methods : {
    ...mapActions('adminCrmSystem', [
      'ADMIN_CRM_GET_BUSINESS',
      'ARCHIVE_BUSINESS' ,
    ] ),

    // techincal

    async initPage() {
      this.entity = await this.ADMIN_CRM_GET_BUSINESS(this.uuid)
    }
  },
  beforeMount() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>